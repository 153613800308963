// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';
import axiosLib from 'axios';

import { filterDeepErrorsByField } from '@utils/validate';

import commonSlice from '../common/common';

import { JOBS_FIELD_ERRORS } from './constants';

import { AppDispatch, RootState } from '@store/index';
import { ProfileJobsState, JobItem, ProfileJobsError } from './types';

// TODO: типизировать

export const profileJobsSlice = createSlice({
  name: 'profileJobs',
  initialState: {
    initialProfileJobs: [],
    profileJobs: [],
    isEditJobs: false,
    jobForHeader: {
      company: 'Не указано',
      position: 'Не указано',
    },
    changedJobIds: [],
    errors: [],
    communityError: '',
  } as ProfileJobsState,
  reducers: {
    setProfileJobs: (state, { payload }) => {
      state.profileJobs = payload;
    },
    setInitialProfileJobs: (state, { payload }) => {
      state.initialProfileJobs = payload;
    },
    setIsEditJobs: (state, { payload }) => {
      state.isEditJobs = payload;
    },
    setJobForHeader: (state, { payload }) => {
      state.jobForHeader = payload;
    },
    setChangedJobIds: (state, { payload }) => {
      state.changedJobIds = [...state.changedJobIds, payload];
    },
    setErrors: (state, { payload }: { payload: ProfileJobsError }) => {
      const findingError = state.errors.find((error) => error.id == payload.id);

      state.errors = findingError
        ? state.errors.map((error) => {
            if (error.id === payload.id) {
              return {
                ...error,
                place: payload.place === '' ? '' : payload.place || error.place,
                activity: payload.activity === '' ? '' : payload.activity || error.activity,
                position: payload.position === '' ? '' : payload.position || error.position,
              };
            }
            return error;
          })
        : [...state.errors, payload];
    },
    setCommunityError: (state, { payload }: { payload: string }) => {
      state.communityError = payload;
    },
    resetProfileJobs: (state) => {
      state.initialProfileJobs = [];
      state.profileJobs = [];
      state.jobForHeader = {
        company: 'Не указано',
        position: 'Не указано',
      };
      state.isEditJobs = false;
      state.changedJobIds = [];
      state.errors = [];
      state.communityError = '';
    },
  },
});

export const saveJobs = (userId: number, jobs) => async (dispatch, getState) => {
  const newJobs = jobs.filter((job) => !job.id);

  if (newJobs.length > 0) {
    dispatch(saveProfileJobs(userId, newJobs));
  }

  const updatedJobs = jobs.filter((job) => job.id && job.place !== '');

  const uniqueChangedIds = getState().profileJobs.changedJobIds.filter((number, index, array) => {
    return array.indexOf(number) === index;
  });

  if (uniqueChangedIds.length > 0) {
    const jobsForUpdate = updatedJobs.filter((obj) => uniqueChangedIds.includes(obj.id));

    dispatch(updateProfileJobs(userId, jobsForUpdate));
  } else {
    dispatch(profileJobsSlice.actions.setIsEditJobs(false));
  }

  const deletedJobs = getState().profileJobs.initialProfileJobs.filter(
    (initialJob) => !jobs.some((currentJob) => currentJob.id === initialJob.id),
  );

  if (deletedJobs.length > 0) {
    dispatch(deleteProfileJobs(userId, deletedJobs));
  }
};

export const fetchProfileJobs = (userId) => async (dispatch) => {
  dispatch(commonSlice.actions.setLoading(true));

  try {
    const response = await axios.get(`/profile/${userId}/jobs`);

    if (response.data) {
      dispatch(profileJobsSlice.actions.setProfileJobs(response.data));
      dispatch(profileJobsSlice.actions.setInitialProfileJobs(response.data));

      const jobForHeader = response.data.find((job) => job.isMain);

      if (jobForHeader) {
        dispatch(
          profileJobsSlice.actions.setJobForHeader({
            company: jobForHeader?.place || 'Не указано',
            position: jobForHeader?.position || 'Не указано',
          }),
        );
      }
    }
    dispatch(commonSlice.actions.setLoading(false));
  } catch (e) {
    // TODO handle error
    dispatch(commonSlice.actions.setLoading(false));
  }
};

export const saveProfileJobs =
  (userId: number, jobsData: JobItem[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(commonSlice.actions.setLoading(true));

    const emptyJobs = jobsData.filter(
      (job) => job.place !== '' && job.position !== '' && !job.fieldsIds.length,
    );

    const filteredCurrentProfileJobs = getState().profileJobs.profileJobs?.filter(
      (job) => job.place !== '' && job.position !== '' && !job.fieldsIds.length,
    );

    dispatch(profileJobsSlice.actions.setProfileJobs(filteredCurrentProfileJobs));

    if (!emptyJobs.length) return null;

    try {
      await axiosLib.all(jobsData.map((job) => axios.post(`/profile/${userId}/jobs`, job)));
      await dispatch(fetchProfileJobs(userId));

      dispatch(profileJobsSlice.actions.setIsEditJobs(false));
      dispatch(commonSlice.actions.setLoading(false));
    } catch (e) {
      if (e?.response.data.statusCode === 400) {
        dispatch(handleValidateErrors(e));
      }

      dispatch(commonSlice.actions.setLoading(false));
    }
  };

export const updateProfileJobs = (userId, jobsData) => async (dispatch) => {
  dispatch(commonSlice.actions.setLoading(true));

  try {
    await axiosLib.all(
      jobsData.map((job) => {
        const { id, ...rest } = job;
        return axios.patch(`/profile/${userId}/jobs/${id}`, rest);
      }),
    );
    await dispatch(fetchProfileJobs(userId));

    dispatch(profileJobsSlice.actions.setIsEditJobs(false));
    dispatch(commonSlice.actions.setLoading(false));
  } catch (e) {
    if (e?.response.data.statusCode === 400) {
      dispatch(handleValidateErrors(e));
    }

    dispatch(commonSlice.actions.setLoading(false));
  }
};

export const deleteProfileJobs = (userId, jobsData) => async (dispatch) => {
  dispatch(commonSlice.actions.setLoading(true));
  try {
    await axiosLib.all(jobsData.map((job) => axios.delete(`/profile/${userId}/jobs/${job.id}`)));
    await dispatch(fetchProfileJobs(userId));
    dispatch(commonSlice.actions.setLoading(false));
  } catch (e) {
    // TODO handle error
    dispatch(commonSlice.actions.setLoading(false));
  }
};

const handleValidateErrors = (requestError) => async (dispatch, getState) => {
  const errors = requestError?.response?.data?.message;
  const filteredErrors = filterDeepErrorsByField(errors, JOBS_FIELD_ERRORS);

  if (filteredErrors.length > 0) {
    filteredErrors.forEach((err) => {
      dispatch(
        profileJobsSlice.actions.setErrors({
          ...getState().profileJobs.errors,
          ...err,
        }),
      );
    });
  }

  dispatch(profileJobsSlice.actions.setIsEditJobs(true));
};

export default profileJobsSlice;
